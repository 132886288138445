import { debounce } from '@mui/material'
import TextError from 'components/domains/User/TextError'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import { useProfileUser } from 'hooks/domains/User'
import { setPeopleProperties } from 'helpers/analytics'
import isEmpty from 'helpers/is-empty'
import { getLocalStorage } from 'helpers/local-storage'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { updateReferralCode } from 'services/user'
import trackEvent from 'src/trackers'
import AutoFillReferralCodeInfo from 'components/commons/ReferralCodeInfo'
import MessageBox from 'components/commons/MessageBox'
import SubmitButton from 'components/domains/User/SubmitButton'
import BottomDrawer from 'components/commons/BottomDrawer/v2'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import appConfig from 'configs/app'
import {
  useProfileUserStore,
  useReferralStore,
} from 'stores/domains/User'
import { useReferralInfo } from 'hooks/domains/User/useReferral'
import { useFollowStore } from 'stores/domains/Social'
import { STATUS_JOIN } from 'constants/socials'
import { isGuestMode } from 'helpers/cookie'
import { useTranslation } from 'react-i18next'

const StyledImage = styled('img')({
  width: '240px',
  height: '180px',
  objectFit: 'contain',
  objectPosition: 'center',
})

const SubmitReferralCodeDrawer = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const { query, pathname } = useRouter()
  const trackerData = { query, pathname }
  const [isRequesting, setIsRequesting] = useState(false)
  const [messageBox, setMessageBox] = useState('')
  const [isErrorSubmit, setIsErrorSubmit] = useState(false)
  const [successSubmitReferral, setSuccessSubmitReferral] =
    useState(false)

  const { refetch: refetchUser } = useProfileUser()
  const { profileData } = useProfileUserStore()
  const { isError, refetch: getReferralInfo } = useReferralInfo(
    getLocalStorage('referralCode')?.code,
  )

  const {
    setShowReferralInfoDrawer,
    clearReferralCodeInfo,
    errorMessage,
    showReferralInfoDrawer,
    referralCodeInfo,
  } = useReferralStore((state) => ({
    setShowReferralInfoDrawer: state.setShowReferralInfoDrawer,
    clearReferralCodeInfo: state.clearReferralCodeInfo,
    errorMessage: state.errorMessage,
    referralCodeInfo: state.referralCodeInfo,
    showReferralInfoDrawer: state.showReferralInfoDrawer,
  }))

  const { setIsOpenSuggestFollowModal } = useFollowStore((state) => ({
    setIsOpenSuggestFollowModal: state.setIsOpenSuggestFollowModal,
  }))

  const handleSubmitReferralCode = async () => {
    setIsRequesting(true)
    try {
      const response = await updateReferralCode(
        referralCodeInfo.user?.referral_code,
      )
      if (response?.data?.code === 'SUCCESS') {
        trackEvent.user('submit_referral_code', trackerData, {
          referral_code: referralCodeInfo.user?.referral_code,
          refer_by_CL: !isEmpty(getLocalStorage('referralCode')),
          referrer_username: referralCodeInfo.user?.name,
          referrer_code: referralCodeInfo.user?.referral_code,
          use_referral_code_link: !isEmpty(
            getLocalStorage('referralCode'),
          ),
        })
        setPeopleProperties({
          refer_by_CL: !isEmpty(getLocalStorage('referralCode')),
          referrer_username: referralCodeInfo.user?.name,
          referrer_code: referralCodeInfo.user?.referral_code,
          use_referral_code_link: !isEmpty(
            getLocalStorage('referralCode'),
          ),
        })
        setIsErrorSubmit(false)
        setShowReferralInfoDrawer(false)
        setSuccessSubmitReferral(true)
        refetchUser()
        if (
          referralCodeInfo.community?.mask_id &&
          Boolean(referralCodeInfo.community?.status)
        ) {
          if (
            STATUS_JOIN[referralCodeInfo.community.status_join] !==
              'accept' &&
            STATUS_JOIN[referralCodeInfo.community.status_join] !==
              'in_progress'
          )
            setTimeout(() => {
              setIsOpenSuggestFollowModal(true)
              setSuccessSubmitReferral(false)
            }, 500)
        } else {
          setTimeout(() => {
            setSuccessSubmitReferral(false)
          }, 3000)
          clearReferralCodeInfo()
        }
      }
    } catch (error) {
      setIsErrorSubmit(true)
      setMessageBox(
        error?.response?.data?.message
          ? error.response.data.message
          : 'Ups, terjadi kesalahan, coba beberapa saat lagi',
      )
      setTimeout(() => {
        setMessageBox('')
        setIsErrorSubmit(false)
      }, 2000)
      setIsRequesting(false)
    }
  }

  const handleCloseReferralInfoDrawer = () => {
    setShowReferralInfoDrawer(false)
    clearReferralCodeInfo()
  }

  useEffect(() => {
    if (
      (!isEmpty(profileData.username) &&
        !isEmpty(profileData.referrer_code)) ||
      successSubmitReferral
    ) {
      setShowReferralInfoDrawer(false)
    }
  }, [profileData, successSubmitReferral])

  useEffect(() => {
    if (referralCodeInfo.is_guest && !isGuestMode()) {
      getReferralInfo()
    }
  }, [referralCodeInfo])

  if (successSubmitReferral) {
    return (
      <BottomDrawer
        title={' '}
        open={successSubmitReferral}
        onClose={debounce(() => {
          handleCloseReferralInfoDrawer()
          setSuccessSubmitReferral(false)
        }, 250)}
        closeButtonId="b-users-close-referral-success-drawer"
        actions={[]}
        sx={{
          [theme.breakpoints.up('sm')]: {
            '& .MuiPaper-root': {
              position: 'absolute',
              transform: 'translate(-50%, -50%) !important',
              left: '50%',
              top: '50%',
              height: 'fit-content',
              margin: '0px 0px',
              paddingBottom: '24px',
            },
          },
        }}
      >
        <Stack
          spacing={3}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <StyledImage
            src={`${appConfig.assetBaseUrl}/webp/rocket_success_light.webp`}
            alt="success-referral"
          />
          <Stack spacing={1}>
            <Typography
              sx={(theme) => ({
                ...theme.typography.h3,
                color: theme.palette.text.primary,
                textAlign: 'center',
              })}
            >
              Sukses Pakai Referral Code
            </Typography>
            <Typography
              sx={(theme) => ({
                ...theme.typography.normalRegular,
                color: theme.palette.text.secondary,
                textAlign: 'center',
              })}
            >
              Kamu telah berhasil menggunakan referral code. Yuk,
              mulai explore TipTip sekarang
            </Typography>
          </Stack>
        </Stack>
      </BottomDrawer>
    )
  }

  return (
    <BottomDrawer
      title="Kode Referral"
      open={showReferralInfoDrawer}
      onClose={debounce(handleCloseReferralInfoDrawer, 250)}
      closeButtonId="b-users-close-referral-info-drawer"
      actions={[]}
      sx={{
        [theme.breakpoints.up('sm')]: {
          '& .MuiPaper-root': {
            position: 'absolute',
            transform: 'translate(-50%, -50%) !important',
            left: '50%',
            top: '50%',
            height: 'fit-content',
            margin: '0px 0px',
            borderRadius: '12px',
            paddingBottom: '24px',
          },
        },
      }}
    >
      <Stack
        sx={{
          marginBottom: '-16px',
          paddingBottom: {
            xs: '24px',
            md: 0,
          },
        }}
      >
        {isError ? (
          <TextError text={errorMessage} />
        ) : (
          <>
            <AutoFillReferralCodeInfo
              isChecked
              containerStyle={{ margin: '0px' }}
            />
            {!isEmpty(messageBox) && isErrorSubmit && (
              <MessageBox
                open={!isEmpty(messageBox)}
                onClose={() => setMessageBox('')}
                isError={isErrorSubmit}
                message={messageBox}
              />
            )}
            <SubmitButton
              id="b-users-submit-referral-code"
              sx={{ width: '100%', marginRight: '8px' }}
              text={t('save')}
              disabled={isRequesting}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleSubmitReferralCode()
              }}
            />
          </>
        )}
      </Stack>
    </BottomDrawer>
  )
}

export default SubmitReferralCodeDrawer
