import { useEffect, useState } from 'react'
import appConfig from 'configs/app'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  CoinInfoContainer,
  ErrorDesc,
  ErrorLabel,
  MainTypography,
  StyledButtonVoucher,
  StyledCheck,
  StyledDivider,
  StyledErrorMessage,
  StyledInputVoucher,
  StyledInputWrapper,
  StyledLink,
  StyledSuccessMessage,
  SubTypography,
} from './styled'
import { CircularProgress, IconButton, Skeleton } from '@mui/material'

import { useStoreActions, useStoreState } from 'stores/hooks'
import VoucherCard from 'components/domains/User/VoucherCard'
import trackEvent from 'src/trackers'

import { ICartData } from 'containers/domains/Wallet/PaymentMethodRadio'
import { voucherInbox } from 'services/campaign'
import { IProductsProps } from 'services/payment'
import { getIdToken } from 'helpers/auth'
import { ICommunity } from 'stores/domains/Social/Community/interface'
import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import { useTranslation } from 'react-i18next'

function numberFormatter(num) {
  return new Intl.NumberFormat('id-ID').format(num)
}

function VoucherSkeleton() {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        height={211}
        width={'100%'}
        style={{
          marginTop: 16,
          background: 'lightgrey',
          borderRadius: '8px',
        }}
      />
    </Box>
  )
}
interface IVoucherInbox {
  handleVoucherClear?: Function
  voucherInboxOpen?: Function
  cartValues: ICartData
  trackerData?: any
  page?: boolean
  selectedProducts?: {
    products: Array<IProductsProps>
    payment_method: string
    checkout_source: string
    user_mask_id?: string
    voucher_code: string
    idempotentKey: string
  }
  idempotentKey: string
  itemType?: string
  community?: ICommunity
}

export default function VoucherInbox({
  handleVoucherClear,
  voucherInboxOpen,
  trackerData,
  page,
  selectedProducts,
  idempotentKey,
  itemType = 'content',
  community,
}: IVoucherInbox) {
  const { t } = useTranslation()

  const [showSendButton, setShowSendButton] = useState(false)
  const [voucherList, setVoucherList] = useState([])
  const [isVoucherLoading, setIsVoucherLoading] = useState(false)

  const {
    isLoading,
    isError,
    errorMessage,
    voucherData,
    inputVoucherCode,
  } = useStoreState((state) => state.voucherModel)

  const {
    checkVoucherData,
    setVoucherDetails,
    setVoucherData,
    setInputVoucherCode,
  } = useStoreActions((action) => action.voucherModel)

  useEffect(() => {
    if (voucherData !== null) {
      voucherInboxOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherData, errorMessage, isError])

  function voucherChecker(voucherName) {
    voucherTracker('click_voucher_code_fill_in', voucherName)
    if (page && selectedProducts) {
      setVoucherData(null)
      checkVoucherData({
        products: selectedProducts?.products,
        user_mask_id: '',
        voucher_code: voucherName,
        payment_method: selectedProducts?.payment_method,
        idempotentKey: idempotentKey,
        checkout_source: selectedProducts?.checkout_source,
      })
    }
  }

  useEffect(() => {
    async function handleVoucherInboxV2() {
      try {
        setIsVoucherLoading(true)
        setVoucherList(
          await voucherInbox({
            products: selectedProducts.products,
            payment_method: selectedProducts?.payment_method,
            idempotentKey: idempotentKey,
            checkout_source: selectedProducts?.checkout_source,
          }),
        )
        setIsVoucherLoading(false)
      } catch (err) {
      } finally {
        setIsVoucherLoading(false)
      }
    }
    if (page && selectedProducts) {
      handleVoucherInboxV2()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts])

  function voucherDetailsHandler(data) {
    setVoucherDetails(data)
  }
  function tooglerShowHideButton() {
    setShowSendButton(!showSendButton)
  }
  function handleVoucherCodeChange(newValue) {
    setInputVoucherCode(newValue)
  }
  const voucherTracker = (eventName, voucherCode) => {
    return trackEvent.payment(
      eventName,
      { ...trackerData, itemType },
      {
        modal_name: `PURCHASE_${itemType.toLocaleUpperCase()}`,
        voucher_code: voucherCode,
        user_type: getIdToken() ? 'supporter' : 'guest',
        event_quantity:
          itemType === 'event'
            ? selectedProducts.products[0].tickets[0].qty
            : '',
        is_member_community: community?.is_member || '',
      },
    )
  }

  const redirectURL = (url) => {
    window.open(url, '_blank')
  }
  const RenderVoucherStatus = () => {
    if (voucherData) {
      return (
        <StyledSuccessMessage>
          Kode voucher berhasil diterapkan. Segera selesaikan
          pembayaran untuk mendapatkan potongan diskon Rp
          {numberFormatter(
            voucherData?.payment_method[1]?.voucher_discount,
          )}
          .
        </StyledSuccessMessage>
      )
    } else if (errorMessage?.code) {
      if (
        errorMessage?.code.toUpperCase() === 'NOT_ACCEPTABLE_VOUCHER'
      ) {
        return (
          <StyledErrorMessage>
            Voucher tidak dapat digunakan untuk transaksi ini. Cek
            syarat dan ketentuan berlaku{' '}
            <StyledLink
              onClick={() => {
                voucherTracker(
                  'click_voucher_code_tnc',
                  inputVoucherCode,
                )
                redirectURL(`${appConfig.supportCenterUrl}home`)
              }}
            >
              disini.
            </StyledLink>
          </StyledErrorMessage>
        )
      } else {
        return (
          <StyledErrorMessage>
            {errorMessage?.message}
          </StyledErrorMessage>
        )
      }
    } else if (isError && !voucherData) {
      return (
        <StyledErrorMessage>
          Maaf terjadi kesalahan pada sistem. Silakan coba beberapa
          saat lagi
        </StyledErrorMessage>
      )
    }
  }

  return (
    <Box>
      <MainTypography>
        {t('voucherInboxScreenSearchBarTitle')}
      </MainTypography>
      <SubTypography>
        {t('voucherInboxScreenSearchBarDesc')}
      </SubTypography>
      <StyledInputWrapper>
        <StyledInputVoucher
          border={inputVoucherCode && voucherData}
          isError={isError}
          autoComplete="off"
          onChange={(e) => {
            handleVoucherCodeChange(e.target.value.toUpperCase())
            setVoucherData(null)
          }}
          onBlur={() => {
            trackEvent.paymentCart(`click_search_box`, trackerData, {
              keyword_search: inputVoucherCode,
            })
            tooglerShowHideButton()
          }}
          onFocus={() => {
            tooglerShowHideButton()
          }}
          value={inputVoucherCode}
          placeholder={t('voucherInboxScreenSearchBarHint')}
          startAdornment={
            inputVoucherCode && voucherData && <StyledCheck />
          }
          endAdornment={
            inputVoucherCode && (
              <IconButton
                onMouseDown={(event) => {
                  event.preventDefault()
                }}
                onClick={() => {
                  handleVoucherClear()
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            )
          }
        />
        {showSendButton && !isLoading ? (
          <StyledButtonVoucher
            buttonVariant="filled"
            onMouseDown={(event) => {
              event.preventDefault()
            }}
            disabled={inputVoucherCode !== '' ? false : true}
            onClick={() => {
              voucherChecker(inputVoucherCode)
            }}
          >
            {t('voucherInboxScreenButtonApplyVoucher')}
          </StyledButtonVoucher>
        ) : isLoading ? (
          <CircularProgress style={{ marginLeft: 40 }} />
        ) : (
          ''
        )}
      </StyledInputWrapper>
      <CoinInfoContainer>{RenderVoucherStatus()}</CoinInfoContainer>

      {voucherList.length > 0 && (
        <MainTypography>
          <StyledDivider />
          {t('key_or_user_voucher')}
        </MainTypography>
      )}
      {isVoucherLoading ? (
        <VoucherSkeleton />
      ) : voucherList.length > 0 && !isVoucherLoading ? (
        voucherList.map((voucherListData, itemIndex) => {
          return (
            <VoucherCard
              key={itemIndex}
              voucherListData={voucherListData}
              voucherChecker={(voucherName) => {
                voucherChecker(voucherName)
                setInputVoucherCode('')
              }}
              voucherDetailsHandler={voucherDetailsHandler}
              isLoading={isLoading}
              trackerData={trackerData}
            />
          )
        })
      ) : (
        <Box>
          <StateImage
            type="error"
            sx={{
              marginBottom: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ErrorLabel>Belum ada voucher untuk digunakan</ErrorLabel>
          <ErrorDesc>
            Coba kembali lagi nanti ya, atau coba masukkan kode promo.
          </ErrorDesc>
        </Box>
      )}
    </Box>
  )
}
