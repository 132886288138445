import {
  Tabs,
  useTheme,
  Tooltip,
  ClickAwayListener,
} from '@mui/material'
import { useState, useEffect } from 'react'
import {
  CopyWrapperItems,
  LabelWrapper,
  StyledBoldWhite,
  StyledBoldWhiteTitle,
  StyledCopy,
  StyledCopyTitle,
  StyledInfoIcon,
  StyledInfoLabel,
  StyledLink,
  StyledSteps,
  StyledSubSteps,
  StyledTab,
  StyledVaNumber,
} from './styled'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import dateFormatter from 'helpers/formats/date'
import { paymentConfirmation } from 'services/payment'
import { sendLog } from 'helpers/log'
import appConfig from 'configs/app'
import { useFeatureFlags } from 'helpers/feature-flag'
import { validateURL } from 'helpers/url/convert-url'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'
import ExpiryTimeBanner from 'components/domains/Content/ExpiryTimeBanner'
import TimeoutPaymentBanner from 'components/commons/TimeoutPaymentBanner'
import { useTranslation } from 'react-i18next'

const linkify = (
  text: string,
  callback: Function,
  newTab: Boolean = true,
  toolTip: boolean,
  toolTipCloser?: Function,
) => {
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s|()]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s|()]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s|()]{2,}|www\.[a-zA-Z0-9]+\.[^\s|()]{2,}|\d{20})/gi
  return text.split(urlRegex).map((part, index) =>
    index % 2 === 0 ? (
      part
    ) : part.length === 20 ? (
      <ClickAwayListener onClickAway={() => toolTipCloser()}>
        <Tooltip
          key={index}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => toolTipCloser()}
          open={toolTip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Berhasil Disalin!"
        >
          <StyledVaNumber
            onClick={() => {
              callback()
            }}
          >
            {part}
          </StyledVaNumber>
        </Tooltip>
      </ClickAwayListener>
    ) : (
      <StyledLink
        href={`${validateURL(part)}`}
        target={newTab ? `_blank` : ``}
        rel="noreferrer"
      >
        {part}
      </StyledLink>
    ),
  )
}

export { linkify }

interface PaymentInstructionList {
  expiry_date: string
  time_remaining: number
  expiry_message: string
  is_timer_active: boolean
  instructions: Array<any>
  invoice_id: string
  payment_method_code: string
  payment_method_name: string
  total: number
  va: string
  va_name: string
}
interface InstructionsProps {
  paymentInstructionList: PaymentInstructionList
}
function PaymentInstruction({
  paymentInstructionList,
}: InstructionsProps) {
  const { t } = useTranslation()

  const theme = useTheme()
  const [currentActiveTab, setCurrentActiveTab] = useState('ATM')
  const [selectedInstruction, setSelectedInstruction] = useState([])
  const FinishPaymentBCA = useFeatureFlags('finish-payment-bca')
  const [isPendingNotifOpen, setIsPendingNotifOpen] = useState(false)
  const [isTimerActive, setTimerActive] = useState(false)
  const [isTimeoutPaymentShow, setTimeoutPaymentShow] =
    useState(false)

  useEffect(() => {
    Object.keys(paymentInstructionList?.instructions).forEach(
      (listNumber) => {
        if (
          paymentInstructionList?.instructions[listNumber]
            ?.tab_name === currentActiveTab
        ) {
          setSelectedInstruction(
            paymentInstructionList?.instructions[listNumber]?.points,
          )
        }
      },
    )
  }, [currentActiveTab, paymentInstructionList])

  const [open, setOpen] = useState(false)
  const [openCopyVa, setOpenCopyVa] = useState(false)
  const [openCopyTotal, setOpenCopyTotal] = useState(false)
  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(paymentInstructionList?.va)
      setOpen(true)
    } catch (error) {
      sendLog(error)
    }
  }

  const vaConfirmationHandler = async () => {
    try {
      await paymentConfirmation({
        invoice: paymentInstructionList.invoice_id,
      })
    } catch (error) {
      sendLog(`Error: ${error?.code} - ${error?.message}`)
    }
  }
  const handleTimerFinished = () => {
    setTimeoutPaymentShow(true)
    setIsPendingNotifOpen(false)
  }

  const handleCustomDateWithTimeZone = (date) => {
    const dateFormat = dateFormatter({
      date: date,
      format: `DD MMM YYYY, HH:mm`,
    })

    const getTimeZone = dateFormatter({
      date: date,
      format: `ZZ`,
    })

    let timeZone = ''
    switch (getTimeZone) {
      case '+0700':
        timeZone = 'WIB'
        break
      case '+0800':
        timeZone = 'WITA'
        break
      default:
        timeZone = 'WIT'
        break
    }

    return dateFormat + ' ' + timeZone
  }

  useEffect(() => {
    setTimerActive(paymentInstructionList?.is_timer_active)
    if (paymentInstructionList?.is_timer_active) {
      setIsPendingNotifOpen(true)
    }
  }, [paymentInstructionList])

  return (
    <>
      {isTimerActive ? (
        <>
          <ExpiryTimeBanner
            isOpen={isPendingNotifOpen}
            expiryDate={paymentInstructionList?.expiry_date}
            remainingTime={paymentInstructionList?.time_remaining}
            message={paymentInstructionList?.expiry_message}
            sx={{
              margin: '8px 0px',
            }}
            onHandleTimerFinished={handleTimerFinished}
          />
          <TimeoutPaymentBanner
            isOpen={isTimeoutPaymentShow}
            desc={paymentInstructionList?.expiry_message}
          />
        </>
      ) : (
        <LabelWrapper>
          <StyledInfoIcon />
          <StyledInfoLabel>
            {t('vaPaymentInstructionWidgetTextSpan1')}{' '}
            <StyledBoldWhiteTitle>
              {handleCustomDateWithTimeZone(
                paymentInstructionList?.expiry_date,
              )}
            </StyledBoldWhiteTitle>
          </StyledInfoLabel>
        </LabelWrapper>
      )}
      <CopyWrapperItems>
        <StyledCopyTitle>
          {t('vaPaymentInstructionDetailWidgetVirtualNameLabel')}
        </StyledCopyTitle>
        <StyledBoldWhite>
          {paymentInstructionList?.va_name}
        </StyledBoldWhite>
        <StyledCopyTitle>
          {t('vaPaymentInstructionDetailWidgetVirtualNumberLabel')}
        </StyledCopyTitle>
        <StyledBoldWhite>
          {paymentInstructionList?.va}
          <ClickAwayListener onClickAway={() => setOpenCopyVa(false)}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setOpenCopyVa(false)}
              open={openCopyVa}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Berhasil Disalin!"
            >
              <StyledCopy
                onClick={async () => {
                  try {
                    navigator.clipboard.writeText(
                      paymentInstructionList?.va,
                    )
                    setOpenCopyVa(true)
                  } catch (error) {
                    sendLog(error)
                  }
                }}
              >
                <ContentCopyIcon />
                {t('copy')}
              </StyledCopy>
            </Tooltip>
          </ClickAwayListener>
        </StyledBoldWhite>
        <StyledCopyTitle>
          {t('vaPaymentInstructionDetailWidgetTotalLabel')}
        </StyledCopyTitle>
        <StyledBoldWhite>
          Rp{NumberBaseFormatter(paymentInstructionList?.total)}
          <ClickAwayListener
            onClickAway={() => setOpenCopyTotal(false)}
          >
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setOpenCopyTotal(false)}
              open={openCopyTotal}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Berhasil Disalin!"
            >
              <StyledCopy
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(
                      paymentInstructionList?.total.toString(),
                    )
                    setOpenCopyTotal(true)
                  } catch (error) {
                    sendLog(error)
                  }
                }}
              >
                <ContentCopyIcon />
                {t('copy')}
              </StyledCopy>
            </Tooltip>
          </ClickAwayListener>
        </StyledBoldWhite>
        {FinishPaymentBCA &&
          appConfig.environment !== 'production' && (
            <Button onClick={vaConfirmationHandler}>
              Selesaikan Pembayaran
            </Button>
          )}
      </CopyWrapperItems>
      <Box>
        <Tabs
          variant="fullWidth"
          value={currentActiveTab}
          onChange={(_event, val) => {
            setCurrentActiveTab(val)
          }}
          sx={{
            borderBottom: `1px solid ${theme.palette.background.tertiery}`,
          }}
        >
          {paymentInstructionList?.instructions?.map(
            (action, index) => {
              return (
                <StyledTab
                  key={index}
                  label={action?.tab_name}
                  value={action?.tab_name}
                />
              )
            },
          )}
        </Tabs>
      </Box>
      <Box
        style={{
          marginBottom: 16,
          marginTop: 16,
          color: theme.palette.text.primary,
          overflowY: 'scroll',
          height: 200,
        }}
      >
        {selectedInstruction?.map((titleStep, index) => {
          return (
            <div key={index}>
              <StyledSteps>{titleStep.title}</StyledSteps>
              <ol>
                {titleStep?.steps?.map((step, index) => {
                  return (
                    <StyledSubSteps key={index}>
                      {linkify(step, copyLink, true, open, () => {
                        setOpen(false)
                      })}
                    </StyledSubSteps>
                  )
                })}
              </ol>
            </div>
          )
        })}
      </Box>
    </>
  )
}
export default PaymentInstruction
