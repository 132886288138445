import { useState } from 'react'
import { Auth } from 'aws-amplify'
import { StackProps, styled } from '@mui/material'

import { loggedInSSO } from 'constants/no-back-redirect'
import { deleteLastAuthUser } from 'helpers/cognito/token'
import afterLogout from 'helpers/auth/after-logout'
import logoutSSO from 'helpers/auth/logout-sso'
import redirect from 'helpers/redirect'
import { sendLog } from 'helpers/log'
import { getLocalStorage } from 'helpers/local-storage'

import ModalDialog from 'components/commons/Modal/Dialog/v2'
import SubmitButton from 'components/domains/User/SubmitButton'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

import { Container, Title } from './styled'
import CloseIcon from '@mui/icons-material/Close'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import { useTranslation } from 'react-i18next'
import { useAuthStore } from 'stores/domains/User'

type MenuSignOutProps = StackProps

const Caption = styled(Title)(({ theme }) => ({
  ...theme.typography.normalRegular,
  color: theme.palette.text.primary,
  letterSpacing: '0.021px',
}))

export function MenuSignOut({ ...rest }: MenuSignOutProps) {
  const { t } = useTranslation()

  const [showDrawer, setShowDrawer] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)

  const authMethod = getLocalStorage('authMethod')
  const { clearAuthStore } = useAuthStore((state) => ({
    clearAuthStore: state.clearAuthStore,
  }))

  const handleClickButton = () => {
    setShowDrawer(!showDrawer)
  }

  const handleLogout = async () => {
    deleteLastAuthUser()
    setIsRequesting(true)
    Auth.signOut()
      .then(() => {
        afterLogout(() => {
          if (loggedInSSO.includes(authMethod)) {
            logoutSSO()
          }
          clearAuthStore()
          redirect('/')
        })
      })
      .catch((error) => {
        sendLog(error)
      })
  }

  return (
    <Container
      flex={1}
      sx={(theme) => ({
        columnGap: 1.5,
        display: 'flex',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.secondary,
        border: `1px solid ${theme.palette.background.tertiery}`,
      })}
      onClick={handleClickButton}
      {...rest}
    >
      <Stack justifyContent="center" alignItems="center" width="24px">
        <LogoutRoundedIcon
          sx={(theme) => ({
            width: 24,
            height: 24,
            color: theme.palette.text.primary,
          })}
        />
      </Stack>
      <Stack
        flex={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Caption
          variant="h4"
          sx={(theme) => ({
            ...theme.typography.normalBold,
            letterSpacing: '0.021px',
            color: theme.palette.text.primary,
          })}
        >
          {t('signOut')}
        </Caption>
      </Stack>
      <ModalDialog
        title={t('signingOut')}
        open={showDrawer || isRequesting}
        onClose={() => setShowDrawer(false)}
        closeButtonIcon={<CloseIcon color="primary" />}
      >
        <Stack
          spacing={2}
          sx={(theme) => ({
            ...theme.typography.normalBold,
            letterSpacing: '0.021px',
            color: theme.palette.text.primary,
          })}
        >
          <Typography py={1}>
            {t('areYouSureYouWantToSignOut')}
          </Typography>
          <SubmitButton
            text={t('signOutFromThisAccount')}
            loading={isRequesting}
            onClick={handleLogout}
            type="button"
          />
        </Stack>
      </ModalDialog>
    </Container>
  )
}
