import {
  GroupType,
  QuillDeltaToHtmlConverter,
} from 'quill-delta-to-html'
import { urlify } from '../urlify'
import { getUrlWithProtocol } from '../url'

export function renderHtmlDescription(value: any[]) {
  const converter = new QuillDeltaToHtmlConverter(value, {
    linkTarget: '_blank',
    urlSanitizer: (url) => {
      const sanitizeUrl = urlify(url)
      if (!sanitizeUrl.media) {
        if (sanitizeUrl.internal) return getUrlWithProtocol(url)
        return `/external-link?url=${sanitizeUrl.url}`
      }
      return sanitizeUrl.url
    },
  })

  converter.afterRender((groupType, htmlString) => {
    if (groupType === GroupType.Video) {
      const videoUrl = htmlString.match('(?<=src=").*?(?=[?"])')
      return `<video className="ql-video" controls><source src="${videoUrl[0]}" type="video/mp4"></video>`
    }
    return htmlString
  })

  return converter.convert()
}
