import { verificationMethodConst } from 'constants/common'
import isEmpty from 'helpers/is-empty'
import {
  destroyLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage'
import { StateCreator } from 'zustand'
import { IAuth, VerificationAction } from './interface'

export const createAuthStore: StateCreator<IAuth> = (set) => ({
  //states
  isOpenVerificationDrawer: false,
  verificationMethod: '',
  isError: false,
  isLoading: false,
  errorMessage: '',
  verificationAction: '',
  inputEmail: '',
  inputPhoneNumber: '',
  countryData: {
    dial_code: '+62',
    country_code: 'ID',
    allow_sms: true,
  },
  //actions
  setIsOpenVerificationDrawer: (status: boolean) => {
    set({
      isOpenVerificationDrawer: status,
    })
  },
  setVerificationMethod: (method: number) => {
    setLocalStorage('verificationMethod', {
      method: verificationMethodConst[method],
    })
    set({
      verificationMethod: verificationMethodConst[method],
    })
  },
  setVerificationAction: (action: VerificationAction) => {
    setLocalStorage('verificationAction', { action })
    set({
      verificationAction: action,
    })
  },
  setInputEmail: (email: string) => {
    set({
      inputEmail: email,
    })
  },
  setInputPhoneNumber: (phoneNumber: string) => {
    set({
      inputPhoneNumber: phoneNumber,
    })
  },
  setCountryData: (data: any) => {
    set({
      countryData: data,
    })
  },
  checkVerificationMethod: (verificationMethod: string) => {
    // Prevent verification method disapear when user refresh page
    const tempVerificationMethod = getLocalStorage(
      'verificationMethod',
    )?.method

    // If zustand is empty after refresh page, re-assign value from local storage to zustand for verification method
    if (
      isEmpty(verificationMethod) &&
      !isEmpty(tempVerificationMethod)
    ) {
      set({
        verificationMethod: tempVerificationMethod,
      })
    }
  },
  clearAuthStore: () => {
    destroyLocalStorage('verificationAction')
    destroyLocalStorage('verificationMethod')
    destroyLocalStorage('countryData')
    set({
      verificationAction: '',
      isOpenVerificationDrawer: false,
      isError: false,
      isLoading: false,
      errorMessage: '',
      verificationMethod: '',
      inputEmail: '',
      inputPhoneNumber: '',
      countryData: {
        dial_code: '+62',
        country_code: 'ID',
        allow_sms: true,
      },
    })
  },
  resetCountryData: () => {
    set({
      countryData: {
        dial_code: '+62',
        country_code: 'ID',
        allow_sms: true,
      },
    })
  },
})
